
import { Carousel } from 'bootstrap';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HeadCarousel',
  props: {
    id: { type: String, default: 'head-carousel' },
    total: { type: Number, required: true },
  },
  mounted() {
    const carousel = document.querySelector(`#${this.id}`) as HTMLElement | null;
    if (carousel) {
      Carousel.getOrCreateInstance(carousel);
    }
  },
});
