import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "home-view" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "carousel-caption" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "part-title" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "part-item" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "part-item" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "part-item-body" }
const _hoisted_12 = { class: "part-item-title" }
const _hoisted_13 = { class: "part-item-content" }
const _hoisted_14 = { class: "part-item-create" }
const _hoisted_15 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadCarousel = _resolveComponent("HeadCarousel")!
  const _component_PartCarousel1 = _resolveComponent("PartCarousel1")!
  const _component_PartCarousel2 = _resolveComponent("PartCarousel2")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeadCarousel, {
      id: "head-carousel-1",
      total: _ctx.headCarouselItems.length
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headCarouselItems, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass([{ active: item.active }, "carousel-item"])
          }, [
            _createElementVNode("img", {
              src: item.src,
              class: "d-block w-100"
            }, null, 8, _hoisted_2),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", {
                class: "leader",
                innerHTML: item.title
              }, null, 8, _hoisted_4)
            ])
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 8, ["total"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partCarousel1Items, (item, index) => {
      return (_openBlock(), _createBlock(_component_PartCarousel1, {
        id: `part-carousel-1-${index}`,
        class: "pt-4"
      }, {
        title: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: item.stepSrc
            }, null, 8, _hoisted_6)
          ])
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (imgList, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([{ active: index === 0 }, "carousel-item"])
            }, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(imgList, (img) => {
                  return (_openBlock(), _createElementBlock("img", {
                    src: img.src,
                    alt: img.alt ?? '',
                    class: "part-item-img"
                  }, null, 8, _hoisted_8))
                }), 256))
              ])
            ], 2))
          }), 256))
        ]),
        _: 2
      }, 1032, ["id"]))
    }), 256)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partCarousel2Items, (partCarousel2Item, index) => {
      return (_openBlock(), _createBlock(_component_PartCarousel2, {
        id: `part-carousel-2-${index}`,
        reverse: partCarousel2Item.reverse,
        class: "pt-4"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(partCarousel2Item.items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass([{ active: index === 0 }, "carousel-item"])
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: item.img,
                  class: "part-item-img"
                }, null, 8, _hoisted_10),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, _toDisplayString(item.title), 1),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(item.content), 1),
                  _createElementVNode("div", _hoisted_14, _toDisplayString(item.create), 1),
                  _createElementVNode("a", {
                    href: item.href,
                    class: "btn-more"
                  }, " 查看更多 ", 8, _hoisted_15)
                ])
              ])
            ], 2))
          }), 256))
        ]),
        _: 2
      }, 1032, ["id", "reverse"]))
    }), 256))
  ]))
}