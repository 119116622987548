
import { Carousel } from 'bootstrap';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PartCarousel2',
  props: {
    id: { type: String, required: true },
    reverse: { type: Boolean, default: false },
  },
  data() {
    return {
      total: 0,
      index: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const element = document.querySelector(`#${this.id}`);
      if (element) {
        Carousel.getOrCreateInstance(element!);
        element?.addEventListener('slide.bs.carousel', (event) => {
          this.index = event.to;
        });
        this.total = element.querySelectorAll(`#${this.id} .carousel-item`).length;
      }
    });
  },
});
