
import { defineComponent } from 'vue';
import HeadCarousel from '@/components/HeadCarousel.vue';

export default defineComponent({
  name: 'TemplateView2',
  components: {
    HeadCarousel,
  },
  data() {
    return {
      headCarouselItems: [
        {
          src: '/img/0010-0001.jpg',
          title: '定向运动（智力型体育运动）是指利用地图和指北针<br/>依次到访地图上所标示的各个点标，<br/>在成绩有效的前提下，以用时短者为胜的运动',
          active: true,
        },
        {
          src: '/img/0010-0002.jpg',
          title: '定向运动，19世纪后期起源于斯堪的纳维亚半岛，起初是一种军事演习，<br/>意思是在地图和指北针的帮助下，穿越未知地带。<br/>定向运动通常在森林、郊区和城镇或者城市公园里进行，也可以在学校校园里进行。',
        },
      ],
    };
  },
});
