
import { defineComponent } from 'vue';
import HeadCarousel from '@/components/HeadCarousel.vue';
import PartCarousel1 from '@/components/PartCarousel1.vue';
import PartCarousel2 from '@/components/PartCarousel2.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    HeadCarousel,
    PartCarousel1,
    PartCarousel2,
  },
  data() {
    return {
      headCarouselItems: [
        {
          src: '/img/0001-0001.jpg',
          title: '',
          active: true,
        },
        {
          src: '/img/0001-0002.jpg',
          title: '如何让孩子心甘情愿放下手机？',
        },
        {
          src: '/img/0001-0003.jpg',
          title: '趣味定向 活力校园',
        },
        {
          src: '/img/0001-0004.jpg',
          title: '你还在为组织团建而烦恼吗？<br/>速风定向 团建新体验',
        },
      ],

      partCarousel1Items: [
        // 01-社会活动
        {
          stepSrc: '/img/home-step-1-icon.png',
          items: [
            [
              { src: '/img/0002-0001.jpg', alt: '' },
              { src: '/img/0002-0002.jpg', alt: '' },
            ],
            [
              { src: '/img/0002-0003.jpg', alt: '' },
              { src: '/img/0002-0004.jpg', alt: '' },
            ],
            [
              { src: '/img/0002-0005.jpg', alt: '' },
              { src: '/img/0002-0006.jpg', alt: '' },
            ],
          ],
        },
        // 02-亲子活动
        {
          stepSrc: '/img/home-step-2-icon.png',
          items: [
            [
              { src: '/img/0003-0001.jpg', alt: '' },
              { src: '/img/0003-0002.jpg', alt: '' },
            ],
            [
              { src: '/img/0003-0003.jpg', alt: '' },
              { src: '/img/0003-0004.jpg', alt: '' },
            ],
            [
              { src: '/img/0003-0005.jpg', alt: '' },
              { src: '/img/0003-0006.jpg', alt: '' },
            ],
          ],
        },
        // 03-建党活动
        {
          stepSrc: '/img/home-step-3-icon.png',
          items: [
            [
              { src: '/img/0004-0001.jpg', alt: '' },
              { src: '/img/0004-0002.jpg', alt: '' },
            ],
            // [
            //   { src: '/img/0004-0003.jpg', alt: '' },
            //   { src: '/img/0004-0004.jpg', alt: '' },
            // ],
            // [
            //   { src: '/img/0004-0005.jpg', alt: '' },
            //   { src: '/img/0004-0006.jpg', alt: '' },
            // ],
          ],
        },
        // 04-团建活动
        {
          stepSrc: '/img/home-step-4-icon.png',
          items: [
            [
              { src: '/img/0005-0001.jpg', alt: '' },
              { src: '/img/0005-0002.jpg', alt: '' },
            ],
            [
              { src: '/img/0005-0003.jpg', alt: '' },
              // { src: '/img/0005-0004.jpg', alt: '' },
            ],
            // [
            //   { src: '/img/0005-0005.jpg', alt: '' },
            //   { src: '/img/0005-0006.jpg', alt: '' },
            // ],
          ],
        },
        // 05-公益活动
        {
          stepSrc: '/img/home-step-5-icon.png',
          items: [
            [
              { src: '/img/0006-0001.jpg', alt: '' },
              { src: '/img/0006-0002.jpg', alt: '' },
            ],
            [
              { src: '/img/0006-0003.jpg', alt: '' },
              // { src: '/img/0006-0004.jpg', alt: '' },
            ],
            // [
            //   { src: '/img/0006-0005.jpg', alt: '' },
            //   { src: '/img/0006-0006.jpg', alt: '' },
            // ],
          ],
        },
      ],

      partCarousel2Items: [
        // 行业新闻
        {
          reverse: false,
          items: [
            {
              title: '行业新闻',
              content: '2306-07期周末定向运动课',
              create: '2023-03-06',
              img: '/img/0007-0001.jpg',
              href: '/news/1'
            }, {
              title: '行业新闻',
              content: '2305-06期周末定向运动课',
              create: '2023-03-06',
              img: '/img/0007-0002.jpg',
              href: '/news/1'
            }, {
              title: '行业新闻',
              content: '2303-04期',
              create: '2023-02-21',
              img: '/img/0007-0003.jpg',
              href: '/news/1'
            }, {
              title: '行业新闻',
              content: '2301-2302期活动报名',
              create: '2023-02-16',
              img: '/img/0007-0004.jpg',
              href: '/news/1'
            },
          ],
        },
        // 速风新闻
        {
          reverse: true,
          items: [
            {
              title: '速风新闻',
              content: '西城区门头沟区携手成功举办2023年全民健身定向越野活动',
              create: '2023-03-06',
              img: '/img/0008-0001.jpg',
              href: '/news/2'
            }, {
              title: '速风新闻',
              content: '关于2023年全国青少年无线电和定向教育竞赛活动的通知',
              create: '2023-02-28',
              img: '/img/0008-0002.jpg',
              href: '/news/2'
            }, {
              title: '速风新闻',
              content: '2023年陕西省博望定向运动联赛暨汉唐丝路定向跳绳系列赛春赛季…',
              create: '2023-04-19',
              img: '/img/0008-0003.jpg',
              href: '/news/2'
            }, {
              title: '速风新闻',
              content: '2023年湖南省青少年定向越野锦标赛',
              create: '2023-04-19',
              img: '/img/0008-0004.jpg',
              href: '/news/2'
            }, {
              title: '速风新闻',
              content: '公告 | 2023 年陕西省博望定向运动联赛春赛季第一场',
              create: '2023-04-19',
              img: '/img/0008-0005.jpg',
              href: '/news/2'
            }, {
              title: '速风新闻',
              content: '2023年湖南省青少年定向越野锦标赛',
              create: '2023-04-17',
              img: '/img/0008-0006.jpg',
              href: '/news/2'
            },
          ],
        },
        // 通知公告
        {
          reverse: false,
          items: [
            {
              title: '通知公告',
              content: '全国定向锦标赛在重庆大足落幕',
              create: '2023-03-30',
              img: '/img/0009-0001.jpg',
              href: '/news/3'
            }, {
              title: '通知公告',
              content: '全国定向锦标赛在重庆大足落幕',
              create: '2023-03-27',
              img: '/img/0009-0002.jpg',
              href: '/news/3'
            }, {
              title: '通知公告',
              content: '“寻找美丽中华”全国旅游城市定向系列赛开赛',
              create: '2023-02-28',
              img: '/img/0009-0003.jpg',
              href: '/news/3'
            }, {
              title: '通知公告',
              content: '中国无线电和定向运动协会第八届全国代表大会召开',
              create: '2022-04-21',
              img: '/img/0009-0004.jpg',
              href: '/news/3'
            }, {
              title: '通知公告',
              content: '追寻红色足迹 发现魅力繁峙 山西繁峙县开展红色定向赛事',
              create: '2021-09-30',
              img: '/img/0009-0005.jpg',
              href: '/news/3'
            }, {
              title: '通知公告',
              content: '山西繁峙：举办“寻找美丽中华”全国旅游城市定向赛',
              create: '2021-09-29',
              img: '/img/0009-0006.jpg',
              href: '/news/3'
            }, {
              title: '通知公告',
              content: '全国旅游城市定向系列赛代县开跑',
              create: '2021-09-29',
              img: '/img/0009-0007.jpg',
              href: '/news/3'
            }, {
              title: '通知公告',
              content: '智跑定州古城定向赛开赛 任洪国张涛共同鸣笛发令',
              create: '2021-09-29',
              img: '/img/0009-0008.jpg',
              href: '/news/3'
            }, {
              title: '通知公告',
              content: '体育总局职工主题定向比赛开赛',
              create: '2022-07-12',
              img: '/img/0009-0009.jpg',
              href: '/news/3'
            },
          ],
        },
      ],
    };
  },
});
