import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import TemplateView1 from '@/views/TemplateView1.vue';
import TemplateView3 from '@/views/TemplateView3.vue';
import TemplateAboutOrientView from '@/views/TemplateView2.vue';
import HomeView from '@/views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: TemplateView1,
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        meta: {
          theme: ['light', 'bg-gray'], noScroll: true,
        },
        component: HomeView,
      },
      // 关于定向
      {
        path: '/about-orient',
        name: 'about-orient',
        redirect: '/about-orient/history',
        component: TemplateAboutOrientView,
        children: [
          // 关于定向/定向发展历史
          {
            path: '/about-orient/history',
            name: 'about-orient-history',
            component: () => import('@/views/about-orient/HistoryView.vue'),
          },
          // 关于定向/定向的玩法和规则
          {
            path: '/about-orient/rules',
            name: 'about-orient-rules',
            component: () => import('@/views/about-orient/RulesView.vue'),
          },
        ],
      },
      // 新闻中心
      {
        path: '/news',
        name: 'news',
        redirect: '/news/1',
        component: () => import('@/views/news/NewsView.vue'),
        children: [
          {
            path: '/news/1',
            name: 'news-1',
            component: () => import('@/views/news/News1View.vue'),
          },
          {
            path: '/news/2',
            name: 'news-2',
            component: () => import('@/views/news/News2View.vue'),
          },
          {
            path: '/news/3',
            name: 'news-3',
            component: () => import('@/views/news/News3View.vue'),
          },
        ],
      },
    ],
  },
  // 关于速风
  {
    path: '/about-sufeng',
    name: 'about-sufeng',
    redirect: '/about-sufeng/introduction',
    meta: {
      theme: { 'light': true, noScroll: true },
    },
    component: TemplateView3,
    children: [
      // 关于速风/速风简介
      {
        path: '/about-sufeng/introduction',
        name: 'about-sufeng-introduction',
        meta: { contactUs: false },
        component: () => import('@/views/about-sufeng/IntroductionView.vue'),
      },
      // 关于速风/师资团队
      {
        path: '/about-sufeng/team',
        name: 'about-sufeng-team',
        component: () => import('@/views/about-sufeng/TeamView.vue'),
      },
      // 关于速风/教学保障
      {
        path: '/about-sufeng/guarantee',
        name: 'about-sufeng-guarantee',
        component: () => import('@/views/about-sufeng/GuaranteeView.vue'),
      },
      // 关于速风/速风荣誉
      {
        path: '/about-sufeng/honor',
        name: 'about-sufeng-honor',
        component: () => import('@/views/about-sufeng/HonorView.vue'),
      },
      // 关于速风/社会评价
      {
        path: '/about-sufeng/evaluation',
        name: 'about-sufeng-evaluation',
        component: () => import('@/views/about-sufeng/EvaluationView.vue'),
      },
      // 关于速风/召集令
      {
        path: '/about-sufeng/convene',
        name: 'about-sufeng-convene',
        component: () => import('@/views/about-sufeng/ConveneView.vue'),
      },
    ],
  },
  // 服务内容
  {
    path: '/service-content',
    name: 'service-content',
    redirect: '/service-content/after-class',
    meta: {
      theme: { 'light': true, noScroll: true },
    },
    component: TemplateView3,
    children: [
      // 课后服务
      {
        path: '/service-content/after-class',
        name: 'service-content-after-class',
        component: () => import('@/views/service-content/AfterClassView.vue'),
      },
      // 专业校队
      {
        path: '/service-content/school-team',
        name: 'service-content-school-team',
        component: () => import('@/views/service-content/SchoolTeamView.vue'),
      },
      // 周末活动
      {
        path: '/service-content/weekend',
        name: 'service-content-weekend',
        component: () => import('@/views/service-content/WeekendView.vue'),
      },
      // 社会活动
      {
        path: '/service-content/social',
        name: 'service-content-social',
        component: () => import('@/views/service-content/SocialView.vue'),
      },
      // 赛事集训营
      {
        path: '/service-content/camp',
        name: 'service-content-camp',
        component: () => import('@/views/service-content/CampView.vue'),
      },
      // 合作学校
      {
        path: '/service-content/partner-school',
        name: 'service-content-partner-school',
        component: () => import('@/views/service-content/PartnerSchoolView.vue'),
      },
    ],
  },
  // 活动中心
  {
    path: '/activity-center',
    name: 'activity-center',
    redirect: '/activity-center/sign',
    meta: {
      theme: { 'light': true, noScroll: true },
    },
    component: TemplateView3,
    children: [
      // 活动中心/活动报名
      {
        path: '/activity-center/sign',
        name: 'activity-center-sign',
        component: () => import('@/views/activity-center/SignView.vue'),
      },
      // 活动中心/活动案例
      {
        path: '/activity-center/case',
        name: 'activity-center-case',
        component: () => import('@/views/activity-center/CaseView.vue'),
      },
      // 活动中心/活动场地
      {
        path: '/activity-center/case/place',
        name: 'activity-center-case-place',
        component: () => import('@/views/activity-center/PlaceView.vue'),
      },
    ],
  },
  // 会员中心
  {
    path: '/member-center',
    name: 'member-center',
    meta: {
      theme: { 'orange': true, noScroll: true },
    },
    component: TemplateView3,
    children: [
      {
        path: 'index',
        name: 'member-center-index',
        component: () => import('@/views/member-center/index.vue'),
      },
    ],
  },
];

const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, _from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    }

    return { top: 0 };
  },
  routes,
});

export default router;
