<template>
  <div class="contact-us">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <div class="d-flex flex-column">
            <div class="h1 contact-us-title">联系我们/Contact Us</div>

            <div class="entry-list">
              <div class="entry">
                <img class="icon" src="@/assets/img/icon-location.png" alt="Location">
                <div class="content">
                  <p>湖南省长沙市岳麓区西湖街道西湖公园58小镇A3-126室</p>
                  <p>
                    Changsha City, Hunan Province Yuelu District Xihu Street A3-126,58 Town of West Lake Park, Yuelu
                    District
                  </p>
                </div>
              </div>
              <div class="entry">
                <img class="icon" src="@/assets/img/icon-mail.png" alt="E-Mail">
                <div class="content">
                  <p>2927247418@qq.com</p>
                </div>
              </div>
              <div class="entry">
                <img class="icon" src="@/assets/img/icon-tel.png" alt="TEL">
                <div class="content">
                  <p>蒋教练：156 7510 3518</p>
                </div>
              </div>
            </div>

            <div class="content">
              <p>版权所有：长沙速风青少年体育俱乐部</p>
              <a href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2022002228号-3</a>
            </div>
          </div>
        </div>

        <div class="col-lg">
          <a href="https://www.amap.com/place/B0I32CLOMH" target="_blank">
            <img src="/img/map.jpg" class="img-fluid rounded-3" alt="Map">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap-utilities";

.contact-us {
  // width: 100%;
  // background-size: cover;
  background-image: url('~@/assets/img/contact-us.jpg');
  padding-top: 3rem;
  padding-bottom: 3rem;

  .contact-us-title {
    font-family: PingFangSC-Medium, PingFang SC;
    color: #000000;
    padding: 10px 0;
  }

  .entry-list {
    flex-grow: 1;
    max-width: 85%;

    .entry {
      display: flex;
      margin: 10px 0;

      .icon {
        width: 17.82px;
        height: 14px;
        margin-right: 10px;
        line-height: 14px;
        margin-top: 5px;

        @include media-breakpoint-up(md) {
          width: 22.9px;
          height: 18px;
          line-height: 18px;
          margin-top: 2px;
        }


        @include media-breakpoint-up(xl) {
          width: 28px;
          height: 22px;
          line-height: 22px;
          margin-top: 0;
        }
      }
    }
  }

  .content {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 22px;

    a {
      text-decoration: none;
    }
  }
}
</style>