
import { Carousel } from 'bootstrap';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PartCarousel1',
  props: {
    id: { type: String, required: true },
  },
  mounted() {
    this.$nextTick(() => {
      const element = document.querySelector(`#${this.id}`);
      if (element) {
        Carousel.getOrCreateInstance(element);
      }
    });
  },
});
