
import { defineComponent } from 'vue';
import { RouterLink } from 'vue-router';
import LogoSvg from '@/components/LogoSvg.vue';

export default defineComponent({
  name: 'Navbar',
  components: {
    RouterLink,
    LogoSvg,
  },
  computed: {
    theme(): any {
      // 从路由中获取主题
      return this.$route.meta.theme;
    },
    noScroll(): boolean {
      console.log(!!(this.$route.meta.noScroll ?? false));

      // 从路由中获取是否禁止滚动
      return !!(this.$route.meta.noScroll ?? false);
    },
  },
  methods: {
    buildSubNav() {
      const items = document.querySelectorAll('#navbar-1>.container>.nav>.nav-item');
      items.forEach(item => {
        const subNavLinks = item.querySelectorAll('.sub-nav>.nav-item');
        if (subNavLinks.length) {
          const offsetLeft = (item as HTMLElement).offsetLeft;
          const offsetWidth = (item as HTMLElement).offsetWidth;
          subNavLinks.forEach(subNavLink => {
            const item = (subNavLink as HTMLElement);
            item.style.marginLeft = `${offsetLeft - offsetWidth / 2}px`;
            item.style.width = `${offsetWidth * 2}px`;
          });
        }
      });
    },

    windowScroll() {
      if (this.noScroll === true) return;
      const navbar = document.querySelector('#navbar-1') as HTMLElement | null;
      if (navbar) {
        if (window.scrollY > 0) {
          if (!navbar.classList.contains('scrolled')) {
            navbar.classList.add('scrolled');
          }
        } else {
          if (navbar.classList.contains('scrolled')) {
            navbar.classList.remove('scrolled');
          }
        }
      }
    },
  },
  mounted() {
    console.log('Navbar mounted');
    this.$nextTick(this.buildSubNav);
    window.addEventListener('resize', this.buildSubNav);
    window.addEventListener('scroll', this.windowScroll);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.buildSubNav);
    window.removeEventListener('scroll', this.windowScroll);
  },
});
