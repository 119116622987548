
import { defineComponent } from 'vue';
import ContactUs from '@/components/ContactUs.vue';
import Navbar from '@/components/Navbar.vue';
import router from '@/router';

export default defineComponent({
  name: 'TemplateView1',
  components: {
    Navbar,
    ContactUs,
  },
  computed: {
    contactUs() {
      return !!(router.currentRoute.value.meta.contactUs ?? true);
    },
  },
});
