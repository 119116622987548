import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "carousel-caption" }
const _hoisted_3 = { style: {"font-size":".625rem"} }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadCarousel = _resolveComponent("HeadCarousel")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadCarousel, {
      id: "head-carousel-2",
      total: _ctx.headCarouselItems.length
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headCarouselItems, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass([{ active: item.active }, "carousel-item"])
          }, [
            _createElementVNode("img", {
              src: item.src,
              class: "d-block w-100"
            }, null, 8, _hoisted_1),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", {
                  class: "leader",
                  innerHTML: item.title
                }, null, 8, _hoisted_4)
              ])
            ])
          ], 2))
        }), 128))
      ]),
      _: 1
    }, 8, ["total"]),
    _createVNode(_component_router_view)
  ], 64))
}